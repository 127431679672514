export const keyTrans = {
    'EptzLink': 'med.Eptz',
    'BatteryStatus':'BatteryStatus',
    'GBTLSconfig': 'sys.GMTLS',
    'UshieldLogin': 'sys.PinLogin',
    'SmartSoundDetect':'ivs.SmartSoundDetect',
    'Cry':'ivs.AudioCry',
    'Explosion':'ivs.AudioExplosion',
    'CrashingGlass':'ivs.AudioCrashingGlass',
    'Alarm':'ivs.AudioAlarm',
    'Gunshot':'ivs.AudioGunshot',
    'Scream':'ivs.AudioScream',
    'Unknow':'com.Unknow'
};